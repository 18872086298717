module services {
    export module forex {
        export class forexService implements interfaces.forex.IForexService {

            static $inject = ["$resource", "ENV","$http","$timeout", "generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,private $http: ng.IHttpService,private $timeout: ng.ITimeoutService,public generalService: interfaces.applicationcore.IGeneralService) {
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Forex/Save");
            }

            changeStatus(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Forex/ChangeStatus", {
                }, {
                        save: {
                            method: 'POST'
                        }
                    });
            }

            removeAllocation(falId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Forex/RemoveAllocation", {
                    falId: falId
                });
            }

            removeSurrender(fesId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Forex/RemoveSurrender", {
                    fesId: fesId
                });
            }            


            TransferToCFC(fecContractFromId:number, cfcContractToId: number, amountToTransfer:number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Forex/TransferToCFC", {
                    fecContractFromId: fecContractFromId,
                    cfcContractToId: cfcContractToId,
                    amountToTransfer: amountToTransfer
                });
            }            


            getList(): ng.resource.IResourceClass<interfaces.forex.IForexDisplay> {
                return this.$resource<interfaces.forex.IForexDisplay>(this.ENV.DSP_URL + "Forex/GetList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            }

            
            getForex(): ng.resource.IResourceClass<interfaces.forex.IForex> {

                return this.$resource<interfaces.forex.IForex>(this.ENV.DSP_URL + "Forex/GetForex", {
                    Id: '@Id'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                       
                    });
            }

            createForexContract(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Forex/CreateForexContract", {

                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            GetCreateForexDefaults(ownerEntityId): ng.resource.IResourceClass<interfaces.forex.IForexCreateDefaults>
            {
                return this.$resource<interfaces.forex.IForexCreateDefaults>(this.ENV.DSP_URL + "Forex/GetCreateForexDefaults",
                    {
                        ownerEntityId: ownerEntityId
                    }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );
            }

            getSODropdownList(): ng.resource.IResourceClass<
                interfaces.applicationcore.IDropdownModel
            > {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(
                    this.ENV.DSP_URL + "Forex/GetSODropdownList",
                    {
                        ownerEntityId: "@ownerEntityId",
                        customerId: "@customerId",
                        currencyId: "@currencyId",
                        searchText: "@searchText"
                    },
                    {
                        query: {
                            method: "GET",
                            isArray: true
                        }
                    }
                );
            }

            getCfCDropdownList(): ng.resource.IResourceClass<
                interfaces.applicationcore.IDropdownModel
            > {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(
                    this.ENV.DSP_URL + "Forex/GetCFCAccountList",
                    {
                        ownerEntityId: "@ownerEntityId",
                        currencyId: "@currencyId"
                    },
                    {
                        query: {
                            method: "GET",
                            isArray: true
                        }
                    }
                );
            }            

            getPODropdownList(): ng.resource.IResourceClass<
                interfaces.applicationcore.IDropdownModel
            > {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(
                    this.ENV.DSP_URL + "Forex/GetPODropdownList",
                    {
                        ownerEntityId: "@ownerEntityId",
                        supplierId: "@supplierId",
                        currencyId: "@currencyId",
                        searchText: "@searchText"
                    },
                    {
                        query: {
                            method: "GET",
                            isArray: true
                        }
                    }
                );
            }            

            getForexExcel(params: any): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'Forex/GetForexExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;

                url += 'connectSearch=' + btoa(JSON.stringify(searchObject));

                if (params.items)
                {
                    _.forEach(params.items, (item: string) => {
                        url += "&items=" + item;
                    });
                }
                    

                if (params.entityCode)
                    url += "&entityCode=" + params.entityCode;


                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'ForexContracts.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

        }
    }

    angular.module("app").service("forexService", services.forex.forexService);
}